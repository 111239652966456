import { NgModule } from '@angular/core'

import { CommonModule } from '@angular/common'
import { RouterLinkWithHref } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { AuthComponent } from './auth/auth.component'

@NgModule({
  imports: [CommonModule, IonicModule, RouterLinkWithHref],
  declarations: [AuthComponent],
})
export class LayoutsModule {}
