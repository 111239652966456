import { Component } from '@angular/core';
import { TitleService } from '@src/core/services/title.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  constructor(public titleService: TitleService) {
  }
}