<ion-app>
  <ion-header [translucent]="true" class="ion-no-border">
    <ion-toolbar>
      @if (showBackButton()) {
        <ion-button fill="clear" mode="ios" color="dark" (click)="previous()" class="ml-2">
          <ion-icon name="arrow-back" size="large"></ion-icon>
        </ion-button>
      }
      <ion-buttons slot="end" *ngIf="loggedIn">
        <ion-button *ngFor="let item of headerMenuItems" (click)="item.action?.()"
                    [ngClass]="{ 'no-ripple': !item.action }">
          <ion-icon *ngIf="item.icon" [name]="item.icon"></ion-icon>
          <ion-label>{{ item.label }}</ion-label>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-router-outlet class="mt-7" id="main-content"></ion-router-outlet>
</ion-app>
